import MainPage from '../src/pages/MainPage';
import './App.css';

function App() {
  return (
    <div className="App">
      <MainPage />
    </div>
  );
}

export default App;